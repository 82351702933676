#export[data-disabled] {
  pointer-events: none;
  opacity: 0.65;
}

#exportFormat {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background: #01426a;
  color: white;
  margin-left: 1rem;
}

#exportFormat:disabled,
#exportSubmit:disabled {
  opacity: 0.65;
}

#exportFormat option:disabled {
  background: #cccccc;
  color: black;
}

@media only screen and (max-width: 650px) {
  #exportFormat {
    margin-left: 0;
  }

  .exportParent {
    flex-direction: column;
  }

  .exportParent div:first-child {
    margin-bottom: 1rem;
  }
}
