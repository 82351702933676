html,
body {
  height: 100%;
  overflow-x: hidden;
}

body,
#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

header {
  flex-shrink: 0;
}

.panel {
  flex: 1 0 auto;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-m-l {
  margin-left: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2c61cb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-dark1: rgb(52, 58, 64);
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

body {
  font-family: "Poppins", sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/************************ NAVBAR *********************************/

/* nav {
  z-index: 2;
  background-color: var(--primary-dark1);
} */
/* .logo {
  height: 115px;
  width: 8.25rem;
  margin-left: -4.4rem;
  margin-top: -2rem;
  border: 1px solid #343a40;
} */

/*----- MENU LINKS --------*/
.menu-items a,
.menu-items button {
  font-size: 1rem;
}

/* nav ul li {
  text-transform: uppercase;
} */

.navbar-nav > li > a {
  margin-right: -40.75rem;
  margin-left: 50rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 0.25rem;
  padding: 2 !important;
  height: 5rem !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 20rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-red);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-red);
}

.d-block {
  position: relative;
  width: 100%;
  height: 70%;
  background-color: var(--primary-black);
  overflow: hidden;
}

.caption {
  position: relative;
  color: var(--primary-white);
}

/************************ visualization *********************************/
.visualization-wrapper {
  background: var(--primary-light-grey);
  padding: 1rem 0;
}

.visualization-wrapper h1 {
  color: var(--primary-orange);
}

.visualization-image1 {
  width: 13rem;
  background: var(--primary-transparent-black);
  position: center;
  height: 13rem;
  color: #fff;
}

.visualization-text {
  background-color: lightgray;
  font-size: medium;
  height: 5rem;
}

iframe {
  overflow: hidden !important;
}
.visualizationCard {
  height: 20px;
}
#wrap {
  width: 400px;
  height: 400px;
  overflow: hidden;
}
#scaled-frame {
  width: 400px;
  height: 400px;
  zoom: 1.95;
  -moz-transform: scale(1.95);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.95);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

.visualization-image {
  width: 13rem;
  height: 9rem;
  padding: 0 0.5rem;
}

.visualization-image-box {
  position: relative;
  margin: 1rem;
}

/*------------ OVERFLOW BOX ------------------*/
.overflow {
  position: absolute;
  opacity: 0;
  width: 13rem;
  height: 14rem;
  top: 0;
  border: 2px solid var(--primary-red);
  cursor: pointer;
}

.visualization-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}
.main {
  min-height: calc(100vh - 168px);
}

/************************ FOOTER COMPONENT *********************************/
.footer {
  display: flex;
  background: var(--primary-dark1);
  color: var(--primary-dark-grey);
  padding: 3rem 9rem;
  margin-top: auto !important;
  justify-content: space-between;
  color: lightgrey;
  margin-top: 50px !important;
  flex-shrink: 0;
}

.footer a {
  text-decoration: none;
  color: var(--light-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

@media only screen and (max-width: 1024px) {
  .footer {
    padding: 1rem;
  }

  .footer > div:first-child {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    display: flex;
    flex-direction: column;
  }

  .footer > div:first-child {
    margin-right: 0 !important;
    margin-bottom: 2rem;
  }
}

/* loading */
.singleCriteriaContainer,
.doubleCriteriaContainer {
  position: relative;
}
.loading {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  font-size: 1.5rem;
  font-weight: bold;
}

.loading p {
  margin: 0;
}
