/** @format */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Roboto+Flex:opsz,wght@8..144,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@500;600&family=Montserrat:wght@700&display=swap");
.heading {
  text-align: center;
  font-family: "Domine";
  font-weight: bold;
}
.visualCard {
  /* border: 1px solid black; */
  box-shadow: 0 4px 8px 0 rgb(34 34 34 / 25%);
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 20px;
}

.visualCard:hover {
  /* border: 1px solid black; */
  box-shadow: 0 8px 12px 0 rgb(34 34 34 / 50%);
  margin-top: -2px;
}
.visualHeading {
  font-family: "Merriweather", serif;
  font-size: larger;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.btn_global {
  background: #01426a;
  border: none;
  padding: 10px 15px;
  color: white;
  margin-left: 1rem;
}

.visualBtn {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.visualBtn:nth-child(1) {
  display: flex;
  justify-content: center;
}
.visualBtn .btn {
  background: #01426a;
  border: none;
  padding: 10px 15px;
}
.visualIcon {
  font-size: 22px !important;
  margin-left: 10px;
}
.vis-image {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.vTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1d1d1f !important;
  position: relative;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show
  line-clamp: 1;
  -webkit-box-orient: vertical; */
}

.vTitle:hover .tooltipText {
  visibility: visible;
}

.vTitle .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.visualGif {
  margin: 5px;
  height: 170px;
  text-align: center;
}

.visualSummary {
  /* white-space: nowrap;
        overflow: hidden;
        text-overflow: clip; */
  padding-top: 10px;
  font-family: "Roboto Flex", sans-serif;
  font-size: large;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 450px) {
  .visualGif {
    height: auto;
  }

  .vis-image {
    width: 100%;
    height: auto;
  }
}
