* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.panel {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
