.shareParent {
  display: flex;
}

.shareable {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  position: relative;
  background: #f1f0f0;
  padding: 1rem;
  border-radius: 10px;
  align-self: self-start;
}

.shareable strong {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.shareableFeedback {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: lightgreen;
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid green;
  width: 90%;
  text-align: center;
  top: 75px;
  z-index: 10;
  max-width: 300px;
}

.shareableFeedback p {
  margin-bottom: 0;
}

.show {
  opacity: 1;
  transition: opacity 500ms;
}

.hide {
  opacity: 0;
  transition: opacity 500ms;
}
