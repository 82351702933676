header {
  height: 60px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  background: #01426a;
  position: relative;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px 0 0;
  height: 100%;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
  height: 100%;
}

.logo img {
  height: 100%;
  width: auto;
}

.menus {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  color: white;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;

  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #cadfdf;
  color: #333;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #333;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown.dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

* {
  margin: 0;
  padding: 0;
}

#hamburger_menu_icon {
  display: none;
  width: 40px;
  height: 30px;
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999999;
}

#hamburger_menu_icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#hamburger_menu_icon span:nth-child(1) {
  top: 0px;
}

#hamburger_menu_icon span:nth-child(2),
#hamburger_menu_icon span:nth-child(3) {
  top: 12px;
}

#hamburger_menu_icon span:nth-child(4) {
  top: 24px;
}

#hamburger_menu_icon.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#hamburger_menu_icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger_menu_icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#hamburger_menu_icon.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

@media only screen and (max-width: 768px) {
  header {
    height: 45px;
  }

  #hamburger_menu_icon {
    display: block;
  }

  #hamburger_menu_icon.open ~ nav {
    display: flex;
  }

  nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #01426a;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .menus {
    flex-direction: column;
  }

  .menu-items a {
    text-align: center;
  }

  .dropdown {
    left: 50%;
    transform: translateX(-50%);
  }
}
