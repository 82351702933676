.filter {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  position: relative;
  padding-bottom: 1.5rem;
}

.filter select {
  padding: 10px 25px 10px 15px;
  border: none;
  background: #f1f0f0;
  border-radius: 7px;
}

.filter select:nth-child(1) {
  margin-right: 1rem;
}

@media only screen and (max-width: 576px) {
  .filter {
    flex-direction: column;
  }

  .filter select {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
}
