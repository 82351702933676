/** @format */

.modal-dialog.custom-modal {
  max-width: 100vw;
  width: calc(100vw - 125px) !important;
  padding: 4%;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.modal-content {
  width: 100% !important;
}

.modal.show {
  display: flex !important;
  justify-content: center;
}
