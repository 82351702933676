.doubleCriteria {
  align-items: flex-end;
}

@media only screen and (max-width: 650px) {
  .doubleCriteria {
    flex-direction: column;
    align-items: flex-start;
  }

  button.criteria_submit {
    margin-left: 0;
  }
}
