/** @format */
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@500;600&family=Montserrat:wght@700&family=Poppins:wght@200&display=swap");
body {
  margin: 0;
  padding: 0;
  /* height: 700px; */
  width: 100%;
  /* background: radial-gradient(#D78787, #ffffff); */

  /* display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif; */
  /* background: #333; */
}
.text {
  font-family: "Poppins";
  width: 356px;
  margin: 0px 0px 0px 240px;
  padding-top: 100px;
  font-size: 20px;
  font-weight: 900;
}

.chart {
  width: 100%;
  height: 100%;
  display: block;
  /* box-shadow: 0 0 2px 0 #555; */
  border-bottom: 0.25px solid #555;
  /* background: radial-gradient(#D78787, #ffffff); */
}

.bars {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  /* background: #555; */
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  height: 503px;
  /* box-shadow: 0 0 10px 0 #555; */
  border-radius: 5px;
}

.bars li {
  display: table-cell;
  width: 240px;
  height: 510px;
  position: relative;
}

.bars span {
  width: 100%;
  position: absolute;
  bottom: -30px;
  text-align: center;
}

.bars .bar {
  display: block;
  /* background: #A83430; */
  background: #124677;
  width: 100px;
  position: absolute;
  border: solid 1px black;
  bottom: 0;
  margin-left: 25px;
  text-align: center;
  /* box-shadow: 0 0 10px 0 #611e1c; */
  transition: 0.5s;
  transition-property: background, box-shadow;
}

.bars .bar:before {
  color: #fff;
  position: relative;
  bottom: 20px;
}

@media only screen and (max-width: 1024px) {
  div.text {
    margin-left: 50px;
    padding-top: 20px;
  }

  .bars {
    height: 200px;
    margin-top: 50px;
  }

  .bars li {
    height: 208px;
  }

  .bars .bar {
    width: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .bars {
    display: none;
  }

  .chart {
    border-bottom: none;
  }

  div.text {
    width: 100%;
    margin-left: 0;
  }
}
