.singleCriteria {
  align-items: flex-end;
}

@media only screen and (max-width: 650px) {
  .singleCriteria {
    flex-direction: column;
    align-items: flex-start;
  }

  .directional_arrow {
    display: none;
  }

  button.criteria_submit {
    margin-left: 0;
  }
}
